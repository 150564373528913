/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */
import { Box, Divider, Drawer, Grid, List, ListItem, ListItemIcon, SxProps, Theme, Tooltip, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { ONBOARDING, routes } from "../constants/routes";
import { softwareVersion } from "../store/context/dashboardSlice";
import { useAppSelector } from "../store/hooks";
import { Images } from "~/components/GraphicalResources";
import { selectUserPermssions } from "~/store/context/authSlice";
import { useMemo } from "react";
import { settingsSystemOnboardingEnabled } from "~/store/context/settingsSlice";
import { PERMISSION_OBJECT } from "~/types/user";

export const DRAWER_WIDTH = 60;

type Props = {};

const styles = {
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    textAlign: "center",
    "& .MuiDrawer-paper": {
      background: "#226A58",
      width: DRAWER_WIDTH,
      position: "relative",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      border: 0
    },
  },
  logo: {
    padding: "22px 0 24px"
  },
  listContainer: {
    padding: "8px 12px",
    "& a": {
      color: "#0000008a",
    },
  },
  listItem: {
    padding: "8px",
    width: "36px",
    height: "36px",
    marginX: "auto",
    marginBottom: "4px",
    borderRadius: "8px",
    "& .MuiListItemIcon-root": {
      minWidth: 0,
    },
    "& .MuiSvgIcon-root": {
      width: "20px",
      height: "20px",
      fill: "#FFFFFF"
    },
    "&:hover": {
      backgroundColor: "#FFFFFF26",
    },
    "&.Mui-selected": {
      backgroundColor: "#FFFFFF33",
    },
    "& img": {
      width: "20px",
      height: "20px"
    }

  },
  divider: {
    borderColor: "#EFEFEF26",
    marginTop: "8px",
    marginBottom: "12px",
  },
  versionContainer: {
    flexDirection: "column",
    alignItems: "center",
    marginY: "16px"
  },
  versionText: {
    fontSize: "10px",
    fontWeight: 500,
    color: "white",
    opacity: 0.7,
    lineHeight: 1.35
  },
  tooltip: {
    bgcolor: "#292B2F",
    marginLeft: "8px !important",
    padding: "8px",
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 600,
    color: "#F7F7F7",
  }
} satisfies Record<string, SxProps<Theme>>;

const SideDrawerNew = (props: Props) => {
  const location = useLocation();
  const version = useAppSelector(softwareVersion);
  const permissionsMap = useAppSelector(selectUserPermssions);
  const onboardingEnabled = useAppSelector(settingsSystemOnboardingEnabled);

  function canHighlightRouteIcon(pathname: string, routePath: string) {
    if (!routePath || !pathname) return false;

    if (routePath === "/") {
        return pathname === "/";
    }

    const normalizedPathname = pathname.endsWith("/") ? pathname : `${pathname}/`;
    const normalizedRoutePath = routePath.endsWith("/") ? routePath : `${routePath}/`;

    return normalizedPathname.includes(normalizedRoutePath);
  }

  const showOnboarding = useMemo(() => {
    return onboardingEnabled && (
      // At least on of the view permission should exist for the Getting Started page to be visible
      permissionsMap.radioSite?.VIEW || permissionsMap.radioGroup?.VIEW || permissionsMap.dnn?.VIEW || permissionsMap.deviceGroup?.VIEW || permissionsMap.zone?.VIEW || permissionsMap.edge?.VIEW || permissionsMap.device?.VIEW
    )
  }, [onboardingEnabled, permissionsMap]);

  const filteredRoutes = useMemo(() => {
    let prevRouteName = "";
    const filteredRoutes = routes.filter((route) => {

      // Filter out duplicate routes (espcially the duplicate separator elements)
      const currentRouteName = route.name || "";
      if(currentRouteName === prevRouteName) return false;

      if(route.path?.includes(ONBOARDING) && !showOnboarding) return false;
      
      // Include the routes that don't need filtering based on object type
      if (!route.objectType || route.name === "|") {
        prevRouteName = currentRouteName;
        return true;
      }

      // Filter out the routes if any object type is not in the permissions array with VIEW permission
      if (route.objectType?.every(objectType => permissionsMap[objectType as PERMISSION_OBJECT]?.VIEW)) {
        prevRouteName = currentRouteName;
        return true;
      }

      return false;
    })

    // If the first element is a separator, remove it
    if(filteredRoutes[0].name === "|") filteredRoutes.shift();

    // If the last element is a separator, remove it
    if(filteredRoutes[filteredRoutes.length - 1].name === "|") filteredRoutes.pop();

    return filteredRoutes
    
  }, [permissionsMap, showOnboarding]);

  const { versionNumber, buildNumber } = useMemo(() => {
    if (version) {
      const [major, minor, buildNumber] = version.split(".");
      return { versionNumber: `${major ? major : ""}.${minor ? minor : ""}`, buildNumber };
    }
    return { versionNumber: "", buildNumber: "" };
  }, [version])

  return (
    <Drawer variant="permanent" sx={styles.drawer}>
      <Grid height="100%" container flexDirection="column" justifyContent="space-between">
        {/* The following code is picked from the existing Side Drawer component */}
        <List sx={styles.listContainer}>
          <Grid sx={styles.logo}>
            <img src={Images.highway9Logo} alt="" />
          </Grid>
          {filteredRoutes.map((route, index) => {
            if (route.name === "|") return <Divider key={index} sx={styles.divider} />;
            return (
              <Tooltip
                title={route.name}
                aria-label={route.name}
                placement="right"
                key={route.name}
                componentsProps={
                  {
                    tooltip: {
                      sx: styles.tooltip
                    }
                  }
                }
              >
                <Link to={route.path === "/" ? "/" : route.path ?? "#"}>
                  <ListItem sx={styles.listItem} selected={canHighlightRouteIcon(location.pathname, route.path ?? "")}>
                    <ListItemIcon>
                      {route.icon}
                    </ListItemIcon>
                    {/*<ListItemText primary={''} />*/}
                  </ListItem>
                </Link>
              </Tooltip>
            );
          })}
        </List>
      </Grid>
      {version &&
        <Box>
          <Divider sx={styles.divider} />
          <Grid container sx={styles.versionContainer}>
            <Typography variant="caption" sx={styles.versionText}>
              {versionNumber}
            </Typography>
            <Typography variant="caption" sx={styles.versionText}>
              {buildNumber}
            </Typography>
          </Grid>
        </Box>
      }
    </Drawer>
  );
};

export default SideDrawerNew;
