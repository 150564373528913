/**
 * Copyright 2021-2022 Highway9 Networks Inc.
 */

import { CARRIER_MODE, CPI_TYPE, FREQUENCY_SELECTION_LOGIC } from "../constants";
import { DataObject, ObjectReference } from "./dataObject";
import { defaultSite} from "./site";
export default interface Radio extends DataObject {
  availableUpgrades?: RadioAvailableUpgrads[];
  upgradeToRelease?: string;
  altitude?: number;
  azimuth?: number;
  beamwidth?: number;
  caCert?: string;
  installInfo?: InstallInfo;
  carrierAggregationEnabled?: boolean;
  /** @enum {string} "SINGLE_CARRIER" | "DUAL_CARRIER" | "CARRIER_AGGREGATION" */
  carrierMode?: string;
  cellParameters?: CellParameter[];
  creationTime?: number;
  description?: string;
  downTilt?: number;
  edgeConfig?: EdgeConfigItem[];
  edgeId?: string; // remove it from the API and use edgereference one for now , using it for internal references.
  edgeName?: string; // remove it from the API and use edgereference one
  edges?: EdgeReference[];
  /** @enum {string} "4G" | "5G"*/
  edgeNetworkType?: string;
  gain?: number;
  /** @enum {string} "BAD" | "GOOD" | "NA"; */
  health?: string;
  id?: string;
  /** @enum {string} "CONTROLLER" | "EDGE" | "RADIO"; */
  instanceType?: string;
  ipsecCert?: string;
  ipsecConfig?: IPSec;
  ipsecKey?: string;
  /** @enum {string} "PSK" | "CERTIFICATE" | "DISABLED" */
  ipsecMode?: string; // remove it from the API and use the edgereference
  /** @deprecated use serialNumber instead */
  isAddedToRadioManager?: boolean;
  isIpsecUpdateRequired?: boolean;
  isManaged?: boolean;
  isPartnerRadio?: boolean;
  isSimulatedRadio?: boolean;
  localExit?: LocalExit;
  location?: Location;
  floorPlan?: {
    xOffset: number;
    yOffset: number;
  };
  /** @enum {string} "MANUAL" | "GNSS" | "PTP" */
  locationSyncMode?: string;
  mmeIp?: string;
  model?: string;
  name: string;
  plmnId?: string;
  rebootIfRequired?: boolean;
  readonly runtimeInfo?: RuntimeInfo;
  sasConfig?: SASConfig;
  serialNumber?: string;
  technology: string;
  serviceInstanceId?: string;
  serviceInstanceName?: string;
  smallCellCode?: string;
  /** @enum {string} "NOT_DEPLOYED"| "DEPLOYING"| "DEPLOYED"| "FAILED"| "MARKED_FOR_DELETE"| "DELETING"| "CLEAR_SERIAL_NUMBER"| "MARKED_FOR_REBOOT" | "WAITING"| "PROCESSING_WAITING" ; */
  type?: string;
  updatedTime?: number;
  radioGroup?: ObjectReference;
  radioSite?: ObjectReference;
  plan?: {
    mapPoints?: {
      latitude: number;
      longitude: number;
    };
  };
  neighborsAutoAssign?: boolean;
  isBorderRadio?: boolean;
  neighborList?: {
    id: string;
    cellParameters?: {
      cellIndex: number;
      earfcn?: number;
    };
  }[];
  activeSubscribers?: number;
  mobilityProfile?: ObjectReference;
  overrideEdges?: boolean;
  modelType?: string;

  /** @enum {string} "BAICELLS" | "SIMULATOR" | "AIRSPAN" */
  vendorType?: string;
  simulatedAppliance?: ObjectReference;
  synchronizationProfile?: ObjectReference;
}

/**
 * Extends Radio with additional fields
 * @extends Radio with key, statusColor, warnings
 */
export interface RadioX extends Radio {
  key: string | number;
  statusColor: string;
}

export interface EdgeReference {
  edge: {
    id: string;
    name?: string;
    /** @enum {string} "PRIVATE_CELLULAR" | "CARRIER_GATEWAY" */
    type?: string;
  };
  /** @enum {string} 'PSK' | 'CERTIFICATE' | "DISABLED"; */
  ipsecMode: string;
  carriers?:  {
    id: string;
    name?: string;
    type?: string;
    reserved?: boolean;
  }[]
}

export interface RadioAvailableUpgrads {
  creationTime: Date | string;
  recommended: boolean;
  id?: string;
  vendorType: string;
  imageName?: string;
  release: string;
  runtimeInfo?: any;
  unsupported: boolean;
  model?: string;
}
export interface Location {
  edgeEnabled?: boolean;
  enabled?: boolean;
  id?: string;
  latitude?: number;
  /** @enum {string} "GCP" | "AWS" | "PRIVATE CLOUD" */
  locationType?: string;
  longitude?: number;
  /** @enum {string} "roadmap" | "satellite" | "hybrid" | "terrain" */
  view?: string;
  name?: string;
  zoom?: number;
  readonly runtimeInfo?: any;
  tags?: string[];
  vmcEnabled?: boolean;
  zoneInfo?: {
    groupName?: string;
    regionName?: string;
  };
}

export interface InstallInfo {
  images? : {
    imageName: string;
    imageTag: string;
    imageData: string;
    imageId: string;
  }[];
  cablingInfo?: string;
  
}

export interface LocalExit {
  enableStaticIp?: boolean;
  enabled?: boolean;
  ipPool?: string;
  /** @enum {string}  "255.255.255.0"| "255.255.255.128"| "255.255.255.192"| "255.255.255.224"| "255.255.255.240"| "255.255.255.248"; */
  ipPoolNetmask?: string;
  mode?: number;
  staticIpEnd?: string;
  staticIpStart?: string;
}
interface RuntimeInfo {
  cellParameters?: RadioRuntimeInfoCellParameter[];
  connected?: boolean;
  softwareVersion?: string;
  connectedDevicesCount?: number;
  dataInBytes?: number;
  dataOutBytes?: number;
  readonly edgeConfig?: EdgeConfigRT[]; // read only
  edgeId?: string;
  functionCapacity?: {
    carrierAggregationFunctionEnabled?: boolean;
    dualCarrierFunctionEnabled?: boolean;
  };
  url ?: string;
  gpsStatus?: boolean;
  ipAddress?: string;
  interfaceIp?: string;
  lastSyncTime?: number;
  lastInformationTime?: number;
  synchronizationStatus?: string;
  readonly synchronizationInfo?: SyncInfo;
  macAddress?: string;
  mmeStatus?: MmeStatus[];
  plmnList?: string[];
  plmnId?: string;
  sas?: {
    isDoing?: boolean;
    state?: number;
  };
  mobilityParameters?: {
    enableX2?: boolean;
  };
  uptime?: string;
  alarmCount: number;
  health?: string;
  overallHealth?: string;
  vendorType?: string;
  //Neighbor Details for AirSpan radios
  neighborInfo: {
    type: string;
    cellId: number;
    discoveredBy: string;
    earfcn: number;
    band: number;
    handoverType: string;
    id: string;
    name: string;
    neighborOfCell: string;
    pci: number;
    plmnList: string[];
    serialNumber: string;
    x2Status: string;
    status: string;
    tac: number;
  }[];
}

export interface SASConfig {
  /** @enum {string}  "A" | "B" */
  cbsdCategory?: string;
  cpiData?: {
    /** @enum {string} "Operator" | "Thirdparty" */
    cpiType?: string;
    id?: string;
    installCertificationTime?: string;
    name?: string;
    signature?: string; // @deprecated
  };
  fccId?: string;
  /** @enum {string}  "Power,Bandwidth,Frequency"
    | "Power,Frequency,Bandwidth"
    | "Bandwidth,Frequency,Power"
    | "Bandwidth,Power,Frequency"
    | "Frequency,Power,Bandwidth"
    | "Frequency,Bandwidth,Power"; */
  frequencySelectionLogic?: string;
  isOnlyAllowSelectedFrequency?: boolean;
  sasEnabled?: boolean;
  userId?: string;
  overrideSasConfig?: boolean;
}

interface RadioRuntimeInfoCellParameter {
  bandwidth?: number;
  cellIndex?: number;
  earfcn?: number;
  power?: number;
  rfEnabled?: boolean;
  sas?: {
    isDoing?: boolean;
    state?: number;
  };
  rsi: number;
}

export interface CellParameter {
  altitude?: number | null;
  azimuth: number;
  band: string;
  bandwidth: number;
  beamwidth: number;
  cellId?: number;
  cellIndex?: number;
  downTilt: number;
  earfcn: number[];
  gain: number;
  installCertificationTime?: Date;
  pci: number;
  power: number;
  rfEnabled: boolean;
  signature?: string;
  specialSubframePattern: number;
  subframeAssignment: number;
  tac: number;
  eciAutoAssign?: boolean;
  pciAutoAssign?: boolean;
  earfcnAutoAssign?: boolean;
  overrideBandwidth?: boolean;
}
interface EdgeConfigItem {
  id?: string;
  ipsecConfig?: IPSec;
  plmnId?: string;
  mmeIp?: string;
  name?: string;
}

interface IPSec {
  gateway?: string;
  leftAuth?: string;
  leftCert?: string;
  leftId?: string;
  leftSourceIp?: string;
  leftSubnet?: string;
  pskSecretKey?: string;
  rightAuth?: string;
  rightId?: string;
  rightSubnet?: string;
  secretKey?: string;
}

interface EdgeConfigRT {
  id: string;
  ipsecIpAddress: string;
  ipsecMode: string;
  ipsecStatus: string;
}

interface MmeStatus {
  ip?: string;
  status?: boolean;
  plmnId?: string;
}

export interface SyncInfo {
  ptp?: PtpInfo;
}

export interface PtpInfo {
  masterConnectivity: string;
  synchronizationStatus: string;
  holdoverReason: string;
  holdExpiredTime: number;
  activeMasterStatus: string;
  currentGrandMasterClockClass: number;
  currentUtcOffset: number;
}

type BandBandwidthMap = {
  [key: string]: number[];
};

export const bandBandwidthMap: BandBandwidthMap = {
  "B48": [5, 10, 15, 20],
  "N48": [5, 10, 15, 20, 30, 40],
  "N77": [5, 10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  "N78": [5, 10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100],
};

export const defaultRadio: Radio = {
  name: "",
  availableUpgrades: [],
  locationSyncMode: "MANUAL",
  technology: "4G",
  cellParameters: [
    {
      azimuth: 0,
      band: "B48",
      bandwidth: 20,
      beamwidth: 0,
      cellIndex: 1,
      downTilt: 0,
      earfcn: [55340],
      gain: 0,
      pci: 31,
      power: 24,
      rfEnabled: true,
      specialSubframePattern: 7,
      subframeAssignment: 1,
      tac: 63,
      eciAutoAssign: true,
      pciAutoAssign: true,
      earfcnAutoAssign: true,
      overrideBandwidth: false,
    },
    {
      azimuth: 0,
      band: "B48",
      bandwidth: 20,
      beamwidth: 0,
      cellIndex: 2,
      downTilt: 0,
      earfcn: [55540],
      gain: 0,
      pci: 32,
      power: 24,
      rfEnabled: true,
      specialSubframePattern: 7,
      subframeAssignment: 1,
      tac: 63,
      eciAutoAssign: true,
      pciAutoAssign: true,
      earfcnAutoAssign: true,
      overrideBandwidth: false,
    },
  ],
  sasConfig: {
    sasEnabled: false,
    cbsdCategory: "A",
    fccId: "",
    userId: "",
    isOnlyAllowSelectedFrequency: false,
    cpiData: {
      cpiType: CPI_TYPE.THIRD_PARTY,
      id: "",
      name: "",
    },
    frequencySelectionLogic: FREQUENCY_SELECTION_LOGIC.PBF,
  },
  rebootIfRequired: true,
  carrierMode: CARRIER_MODE.CARRIER_AGGREGATION,
  neighborList: [],
  neighborsAutoAssign: true,
  isBorderRadio: false,
  isManaged: true,
  localExit: {
    enabled: false,
    mode: 0,
    ipPool: "10.0.0.0",
    ipPoolNetmask: "255.255.255.0",
    enableStaticIp: false,
    staticIpStart: "10.0.0.1",
    staticIpEnd: "10.0.0.254",
  },
  location: {
    latitude: defaultSite.location.latitude,
    longitude: defaultSite.location.longitude,
    name: defaultSite.location.name,
  },
};
