/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { fillTimeSeriesData } from "~/views/subscribers/graphs/graphHelper";
import { Zone } from "~/types/zone";
import { VMZService } from "~/services/VMZService";
import { policyService } from "~/services";
import { Policy } from "~/types/policy";

type initState = {
  data: Zone[];
  current : Zone | null;
    open : boolean;   
    policies : Policy[];
  loading: boolean;
  metricsLoading: boolean;
  metrics: {
    [key: string]: { id: string; data: [number, number][] }[];
  };
  isEdit: boolean;
};

export const initialState: initState = {
    data: [],
    current : null,
    open : false,
    policies : [],
  loading: true,
  metricsLoading: true,
  metrics: {},
  isEdit: false,
};

export const fetchZones = createAsyncThunk("vmzGroup/fetchZones", async () => {
    try {
        const vmz = await VMZService.getVMZData();
        return vmz;
    } catch (error) {
        console.log(error);
        throw error;
    }
});

export const fetchPolicies = createAsyncThunk("vmzGroup/fetchPolicies", async () => {
    try {
        const policies = await policyService.getPolicies()
        return policies;
    } catch (error) {
        console.log(error);
        throw error;
    }
});

export const fetchZoneMetrics = createAsyncThunk(`zone/fetchZoneMetrics`, VMZService.getMetrics);

export const zoneSlice = createSlice({
  name: "zone",
  initialState,
  reducers: {
    setCurrent : (state, action : PayloadAction<Zone | null>) => {
        state.current = action.payload;
    },
    setZoneOpen : (state, action : PayloadAction<boolean>) => {
        state.open = action.payload;
    },
    setEdit : (state, action : PayloadAction<boolean>) => {
        state.isEdit = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchZones.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchZones.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchZones.rejected, (state, action) => {
        state.data = [];
        state.loading = false;
        console.log(action.error);
      })
      .addCase(fetchZoneMetrics.pending, (state) => {
        state.metricsLoading = true;
      })
      .addCase(fetchZoneMetrics.fulfilled, (state, action) => {
        const data = action.payload;
        data.forEach((obj) => {
          const metricData = obj.metricData?.map((metric) => {
            const id = metric.id;
            if (!metric.dataPoints.length) return { id, data: [] };
            try {
              return {
                id,
                data: fillTimeSeriesData({
                  data: metric.dataPoints,
                  startTime: action.meta.arg.interval.startTime,
                  endTime: action.meta.arg.interval.endTime,
                  interval: action.meta.arg.resolution,
                  fillType: "null",
                  name: obj.metric,
                }),
              };
            } catch (e) {
              console.error("Error in fillTimeSeriesData", e, obj.metric, metric.dataPoints);
              return { id, data: metric.dataPoints.length ? metric.dataPoints : [] };
            }
          });
          state.metrics[obj.metric] = metricData;
          state.metricsLoading = false;
        });
      })
      .addCase(fetchZoneMetrics.rejected, (state, action) => {
        state.metricsLoading = false;
        console.log(action.error);
      });

    builder.addCase(fetchPolicies.fulfilled, (state, action) => {
        state.policies = action.payload;
    });
  }
});

export const zoneActions = zoneSlice.actions;
export default zoneSlice.reducer;

export const zoneData = (state: RootState) => state.zone.data;
export const zoneState = (state : RootState) => state.zone.current;
export const zonePolicies = (state : RootState) => state.zone.policies;
export const zoneOpen = (state : RootState) => state.zone.open;
export const zonesCount = (state: RootState) => state.zone.data.length;
export const zoneLoading = (state: RootState) => state.zone.loading;
export const zoneMetricsLoading = (state: RootState) => state.zone.metricsLoading;
export const zoneMetrics = (state: RootState) => state.zone.metrics;
export const zoneMetric = (metric: string) => (state: RootState) => state.zone.metrics[metric];
export const zoneIsEdit = (state: RootState) => state.zone.isEdit;