/** 
 Copyright Highway9 Networks Inc. 
 */ 
import withStyles from "@mui/styles/withStyles";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import ErrorsComponent from "~/components/ErrorsComponent";

export const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
export const contentStyles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
})
export const DialogBox = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <Dialog onClose={(event, reason) => reason === "backdropClick" ? undefined : props.onClose()} {...other}>
      {children}
    </Dialog>
  );
});

export const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const DialogContent = withStyles(contentStyles)((props) => {
  const { errorsData, warningsData, closeAlert, closeAll, children, ...others } = props
  return <MuiDialogContent  {...others} >
    {(errorsData || warningsData) && <><ErrorsComponent errorsData={errorsData} warningsData={warningsData} closeAlert={closeAlert} closeAll={closeAll} />
    </>
    }
    {children}
  </MuiDialogContent>

})

export const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
