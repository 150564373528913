import { DataObject } from "./dataObject";

/**
 * Copyright 2021-2022 Highway9 Networks Inc.
 */
export interface ISite extends DataObject {
  id?: string;
  name: string;
  description?: string;
  location: {
    latitude: number;
    longitude: number;
    name?: string;
    zoom?: number;
    /** @enum {string} "roadmap" | "satellite" | "hybrid" | "terrain" */
    view?: string;
  };
  macroEnbIdList?: number[];
  attributes: string[];
  /** Not Available in the API - remove on PUT call */
  countAPs?: number;
  connectedAPs?: number;
  children?: any;
  overallStatus?: string;
}

export const defaultSite: ISite = {
  name: "",
  description: "",
  location: {
    latitude: 37.26534961208239,
    longitude: -122.04076419999998,
    name: "20972 Saratoga Hills Rd, Saratoga, CA 95070, USA",
  },
  attributes: []
};

export const undefinedSite: ISite = {
  name: "",
  description: "",
  location: {
    latitude: 0,
    longitude: 0,
    name: "",
  },
  attributes: []
};
