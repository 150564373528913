/** 
 Copyright Highway9 Networks Inc. 
 */
import moment from "moment";
import { decodeJWTToken } from "../views/radios/Import/Utils";
import { encrypt } from "~/helpers/utils";
import { UserInfo } from "~/types/user";

export const authenticationService = {
  login,
  logout,
  getSession,
  isOperator,
  isTenant,
  isPartner,
  validateCurrentUser,
  getSessionInfo,
  get currentUserValue() {
    return getCurrentUser();
  },
  get currentUserInfo() {
    return getCurrentUserInfo();
  },
  isSystemEngineer,
  isTenantAdmin
};

function getCurrentUser() {
  if (typeof window !== "undefined") {
    const state = localStorage.getItem("currentUser");
    if (state) {
      return JSON.parse(state) as UserLocal;
    } else {
      return null;
    }
  }
  return null;
}

function getCurrentUserInfo() {
  if (typeof window !== "undefined") {
    const state = localStorage.getItem("userInfo");
    if (state) {
      return JSON.parse(state) as UserInfo;
    } else {
      return null;
    }
  }
  return null;
}

async function login(username: string, password: string, publicKey: string) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
    body: JSON.stringify({ email: username, password: encrypt(password, publicKey) }),
  };

  try {
    const response = await fetch(`/api/v1/orch/sessions/login`, requestOptions);
    const res = await response.text();
    const data = res && JSON.parse(res);
    if (!response.ok) {
      if ([401].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        authenticationService.logout();
        window.location.reload();
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem("currentUser", JSON.stringify(data));
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function getSession() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  };

  return fetch(`/api/v1/orch/sessions/session`, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        throw response;
      }
      const user = await response.text();
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      console.log("user: " + user);
      localStorage.setItem("currentUser", user);
      return response;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

async function getSessionInfo(email: string, redirectUrl = "/") {
  const res = await fetch(`/api/v1/orch/sessions/login/query?uri=${redirectUrl}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
    redirect: "manual",
    body: JSON.stringify({ email })
  });

  if (!res.ok) {
    throw new Error("Invalid user");
  }

  const data = await res.json();
  if (data.authType === "PASSWORD") {
    return {
      authType: "PASSWORD",
      authKey: data.authKey
    }
  } else if (data.authType === "SAML") {
    return {
      authType: "SAML",
      redirectUrl: data.redirectUrl
    }
  }
}

function logout() {
  // remove user from local storage to log user out
  localStorage.clear();
  sessionStorage.clear();
  // TODO: Good to add a Logout URL to clear the auth cookie.
  // Auth cookie is short lived so any logout attempt made when the cookie is active
  // will lead the the browser spining till the cookie expires in thill then the /sessions/session api will continue to succeed.
  window.location.reload();
}

function isOperator() {
  return Boolean(authenticationService.currentUserValue?.isOperator);
}

function isPartner() {
  return authenticationService?.currentUserValue?.userType === "partner"
}

function isTenant() {
  return authenticationService?.currentUserInfo?.roles.some((item: string) => item === "TENANT_ADMIN" || item === 'READONLY_TENANT')
}

function isSystemEngineer() {
  return authenticationService?.currentUserInfo?.roles.some((item: string) => item === "SYSTEM_ENGINEER_ADMIN")
}

function isTenantAdmin() {
  return authenticationService?.currentUserInfo?.roles.some((item: string) => item === "TENANT_ADMIN");
}

function validateCurrentUser() {
  const currentUserText = localStorage.getItem("currentUser");
  if (currentUserText) {
    const currentUser = JSON.parse(currentUserText);
    if (currentUser.token) {
      const token = currentUser.token;
      const tokenParts = token.split(".");
      if (tokenParts.length > 2) {
        const json = decodeJWTToken(token);
        if (json.exp <= moment.now() / 1000) {
          console.log("deleting user token ...: " + json.exp);
          localStorage.removeItem("currentUser");
        }
      }
    }
  }
}

export interface UserLocal {
  readonly domainName: string
  readonly email: string
  readonly isOperator: boolean
  readonly success: boolean
  readonly token: string
  readonly userId: string
  readonly userName: string
  readonly userType: string
  readonly serviceInstanceName?: string
}