/**
 * Copyright 2021-2022 Highway9 Networks Inc.
 */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { RootState } from "..";
import { CONNECTED, VIEW, IDLE, } from "~/constants";
import { metricHelper } from "../../helpers/metricHelper";
import { dnnService, subscriberService } from "../../services";
import { Device } from "../../types/device";
import { DeviceInfo } from "../../types/deviceInfo";
import { deviceMetricsList, DeviceMetrics, deviceMetricsListAll, DeviceMetricsData, networkMetricsList } from "../../types/DeviceMetric";
import { defaultDNN, IDNN } from "../../types/dnn";
import Radio from "../../types/radio";
import { calcLengths, groupBy } from "~/helpers/utils";
import { fillTimeSeriesData } from "../../views/subscribers/graphs/graphHelper";
import {
  EventGraphData,
  TimeLine,
  TimeLineEvent,
  TimelineLog,
  formatDeviceEventData,
} from "~/views/subscribers/graphs/TimelineGraphHelper";
import { defaultViewHiddenColumns } from "~/views/subscribers/useSubsciberPanel";

// Variables to keep track of the last refresh time, previous device ID, and previous interval for the pingmon data
let lastRefreshTime = 0;
let prevDeviceID = "";
let prevInterval = 0;

type initState = {
  open: boolean;
  loading: boolean;

  importOpen: boolean;
  detailsOpen: boolean;

  networksData: Array<IDNN>;
  currentNetwork: IDNN;
  networkOpen: boolean;

  status: string;
  current: null | Device;
  data: Array<Device>;
  dataDeviceInfo: Array<DeviceInfo>;
  hiddenColumns: Array<string>;
  preset: string;

  timelineGraph: {
    eventShow: "all" | "major" | "error";
    showLogs: boolean;
    loading: boolean;
    timelineData: TimeLine;
    timelineEvents: TimeLineEvent[];
    timestamps: number[][];
    logs: TimelineLog[];
    deviceEvents: EventGraphData[];
  };
  metrics: DeviceMetricsData;
  radioConnectionsQuality: any[];
  deviceGroupMappingID: { [key: string]: number };
  connectedDeviceGroups: { [key: string]: number };
  pingmon: {
    pingmonMetrics: {
      ping: any[];
      loss: any[];
      jitter: any[];
      min: number;
      max: number;
      average: number;
      successRate: number;
    };
    raw: any[];
    showLogs: boolean;
  };

  currentMetrics: {
    [key: string]: DeviceMetrics;
  };
};

const initialState: initState = {
  open: false,
  loading: true,

  importOpen: false,
  detailsOpen: false,

  networksData: [],
  currentNetwork: defaultDNN,
  networkOpen: false,
  deviceGroupMappingID: {},
  connectedDeviceGroups: {},

  status: "",
  current: null,
  data: [],
  dataDeviceInfo: [],
  hiddenColumns: defaultViewHiddenColumns,
  preset: VIEW.DEFAULT_VIEW,

  timelineGraph: {
    eventShow: "major",
    showLogs: false,
    loading: true,
    timelineData: {},
    timelineEvents: [],
    timestamps: [],
    logs: [],
    deviceEvents: [],
  },
  metrics: {
    device_upload_throughput: [],
    device_download_throughput: [],
    sent_bytes: [],
    received_bytes: [],
  },

  radioConnectionsQuality: [],
  pingmon: {
    pingmonMetrics: {
      ping: [],
      loss: [],
      jitter: [],
      min: 0,
      max: 0,
      average: 0,
      successRate: 0,
    },
    raw: [],
    showLogs: false,
  },

  currentMetrics: {},
};

export const fetchDevices = createAsyncThunk(`subscriber/fetchDevices`, async (_, thunk) => {
  try {
    const devices = await subscriberService.getSubscribers();

    const store = thunk.getState() as RootState;
    const radios = store.radio.data;

    return {
      radios,
      devices: devices.sort((a, b) => a.name.localeCompare(b.name)),
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const fetchTimelineGraph = createAsyncThunk(`subscriber/fetchTimelineGraph`, async (id: string, thunk) => {
  const state = thunk.getState() as RootState;
  const { startTime, endTime } = state.utility.time;
  const radioMap = state.radio.radioMap;
  const params = {
    metrics: ["ue_connection_state", "ue_radio_connection"],
    interval: { startTime, endTime },
    resolution: metricHelper.getResolution(startTime, endTime, 300),
    ids: [id],
  };

  const data = await subscriberService.getTimelineData(params, radioMap);

  return data;

});

/* The above code is a TypeScript function that defines a thunk action creator `fetchDeviceMetrics`
using the `createAsyncThunk` function from the Redux Toolkit. This thunk action is used to fetch
device metrics data for a specific subscriber ID asynchronously. */
export const fetchDeviceMetrics = createAsyncThunk(`subscriber/fetchDeviceMetrics`, async (id: string, thunk) => {
  const state = thunk.getState() as RootState;
  const { startTime, endTime } = state.utility.time;
  const params = {
    metrics: ["device_upload_throughput", "device_download_throughput", "sent_bytes", "received_bytes"],
    interval: { startTime, endTime },
    resolution: metricHelper.getResolution(startTime, endTime, 500),
    ids: [id],
  };
  return {
    id,
    data: await subscriberService.getMetrics(params),
    interval: params.interval,
    resolution: params.resolution,
  };
});
export const fetchConnectionQualityMetrics = createAsyncThunk(
  `subscriber/fetchConnectionQualityMetrics`,
  async (id: string, thunk) => {
    const state = thunk.getState() as RootState;
    const { startTime, endTime } = state.utility.time;
    const params = {
      metrics: deviceMetricsList,
      interval: { startTime, endTime },
      resolution: metricHelper.getResolution(startTime, endTime, 500),
      ids: [id],
    };
    return {
      id,
      data: await subscriberService.getMetrics(params),
      interval: params.interval,
      resolution: params.resolution,
    };
  }
);
export const fetchRadioConnectionsQuality = createAsyncThunk(
  `subscriber/fetchRadioConnectionsQuality`,
  subscriberService.getMetrics
);

// Function to calculate the new start time
function calculateNewStartTime(id: string, diff: number, startTime: number, endTime: number): number {
  let newStartTime = startTime;
  const syncDiff = endTime - lastRefreshTime;
  // console.table({ syncDiff, lastRefreshTime, endTime, startTime, diff, prevInterval, id, prevDeviceID,  });
  if (syncDiff <= 60 && prevDeviceID === id && prevInterval === diff) {
    newStartTime = lastRefreshTime;
  } else {
    lastRefreshTime = 0;
  }
  return newStartTime;
}

export const fetchPingmon = createAsyncThunk(`subscriber/fetchPingmon`, async (id: string, thunk) => {
  try{
  const state = thunk.getState() as RootState;
  const { startTime, endTime, diff } = state.utility.time;
  const maxAllowedTimeInterval = 24 * 60 * 60; // 1 day in seconds
  const newStartTime = calculateNewStartTime(id, Math.min(diff, maxAllowedTimeInterval), Math.max(startTime, endTime - maxAllowedTimeInterval ), endTime);
  const params = {
    metrics: ["pingmon"],
    interval: { startTime: newStartTime, endTime, originalStartTime: startTime},
    resolution: metricHelper.getResolution(startTime, endTime, 300),
    ids: [id],
  };

  const fullSync = lastRefreshTime === 0;
  const raw = state.subscriber.pingmon.raw;

  

  console.time("pingmon");
  const data = await subscriberService.getPingmonData(params, fullSync, raw);
  const lastPointTimestamp = moment(data.raw[data.raw.length - 1]?.timestamp).unix();
  // console.table({ fullSync, endTime, startTime, newStartTime, diff, newdiff : endTime-newStartTime, lastRefreshTime, prevDeviceID, prevInterval });
  
  console.timeEnd("pingmon");

  lastRefreshTime = lastPointTimestamp ?? 0;
  prevDeviceID = id;
  prevInterval = diff;

  return data;
} catch (error) {
  console.log("pingmon err : " ,error);
  throw error;
}
});

export const fetchDevicesLatestMetrics = createAsyncThunk(`subscriber/getLatestMetrics`, async (_: void, thunk) => {
  const state = thunk.getState() as RootState;
  const devices = state.subscriber.data;
  const connectedDeviceIds = devices.filter((d) => d.runtimeInfo?.status === CONNECTED).map((d) => d.id) as string[];
  if (connectedDeviceIds.length === 0) return {};

  const params = {
    metrics: deviceMetricsListAll,
    interval: {
      startTime: Math.floor(moment().unix() / 60) * 60 - 1,
        endTime: Math.floor(moment().unix() / 60) * 60,
    },
  resolution: 1,
  ids: connectedDeviceIds,
  };

const metrics = await subscriberService.getMetrics(params);
const deviceMetrics = {} as { [key: string]: DeviceMetrics };
connectedDeviceIds.forEach((id) => {
  deviceMetrics[id] = {
    timestamp: params.interval.endTime,    
  };
});
for (let a = 0; a < metrics.length; a++) {
  const obj = metrics[a];
  const { metric, metricData } = obj;
  for (let i = 0; i < metricData.length; i++) {
    const metricD = metricData[i];
    deviceMetrics[metricD.id][metric] = metricD.dataPoints?.[metricD.dataPoints?.length - 1]?.[1];
  }
}
return deviceMetrics;
});

export const fetchDNN = createAsyncThunk("subscriber/fetchDNN", async () => {
  try {
    const dnn = await dnnService.getDnn();
    return dnn;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const fetchSubscriberDeviceInfo = createAsyncThunk("subscriber/fetchSubscriberDeviceInfo", async () => {
  try {
    const deviceInfoData = await subscriberService.getSubscribersDeviceInfo();
    return deviceInfoData;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const subscriberSlice = createSlice({
  name: "subscriber",
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setValues: (state, action: PayloadAction<Device | null>) => {
      state.current = action.payload;
    },
    setCurrentNetworkValues: (state, action: PayloadAction<IDNN>) => {
      state.currentNetwork = action.payload;
    },
    setCurrentNetworkName: (state, action: PayloadAction<string>) => {
      state.currentNetwork.name = action.payload;
    },
    setCurrentNetworkQci: (state, action: PayloadAction<number>) => {
      state.currentNetwork.defaultQci = action.payload;
    },
    setCurrentNetworkAmbr: (state, action: PayloadAction<{ uplink: number; downlink: number }>) => {
      state.currentNetwork.ambr = action.payload;
    },
    setNetworkOpen: (state, action: PayloadAction<boolean>) => {
      state.networkOpen = action.payload;
    },
    setImportOpen: (state, action: PayloadAction<boolean>) => {
      state.importOpen = action.payload;
    },
    setDetailsOpen: (state, action: PayloadAction<boolean>) => {
      state.detailsOpen = action.payload;
    },

    setHiddenColumns: (state, action: PayloadAction<string[]>) => {
      state.hiddenColumns = action.payload;
    },
    setPreset: (state, action: PayloadAction<string>) => {
      state.preset = action.payload;
    },
    setTimelineGraphLoading: (state, action: PayloadAction<boolean>) => {
      state.timelineGraph.loading = action.payload;
    },
    toggleTimelineLogs: (state) => {
      state.timelineGraph.showLogs = !state.timelineGraph.showLogs;
    },

    togglePingmonLogs: (state) => {
      state.pingmon.showLogs = !state.pingmon.showLogs;
    },

    toggleTimelineEvents: (state) => {
      // major => error => all => major
      const eventing = state.timelineGraph.eventShow;
      if (eventing === "major") {
        state.timelineGraph.eventShow = "error";
      } else if (eventing === "error") {
        state.timelineGraph.eventShow = "all";
      } else if (eventing === "all") {
        state.timelineGraph.eventShow = "major";
      }
    },
  },
  extraReducers: (builder) => {
    builder
      //devices
      .addCase(fetchDevices.fulfilled, (state, action) => {
        const { devices: data, radios } = action.payload;
        const _devices = SyncDeviceWithRuntimeRadioAPs(data, radios);
        const devices = SyncWithMetrics(_devices, state.currentMetrics);
        state.data = devices ?? [];
        // state.data = SyncwithGroups(devices, state.deviceGroups, state.preset) ?? [];

        // sync the current subscriber with the new data
        if (state.current && !state.open) {
          const current = state.data.find((device) => device.id === state.current?.id);
          if (current) {
            state.current = current;
          }
        }
        state.loading = false;

        const grpedDevices = groupBy(data, "groupId") as { [key: string]: Device[] };
        // calculate the length of connected devices for each group
        const connectedLengths = Object.keys(grpedDevices).reduce((acc, key) => {
          acc[key] = grpedDevices[key].filter(
            (device) => device.runtimeInfo?.status === CONNECTED || device.runtimeInfo?.status === IDLE
          ).length;
          return acc;
        }, {} as Record<string, number>);

        state.connectedDeviceGroups = connectedLengths;

        const calcLen = calcLengths(grpedDevices);
        state.deviceGroupMappingID = calcLen;
        state.status = "devices_loaded";
      })
      .addCase(fetchDevices.rejected, (state, action) => {
        state.status = "error_fetching_devices";
      })

      // Network DNN
      .addCase(fetchDNN.fulfilled, (state, action) => {
        state.networksData = action.payload;
        state.loading = false;
        state.status = "dnn_loaded";
      })
      .addCase(fetchDNN.rejected, (state) => {
        state.status = "error_fetching_DNN";
      })

      // Timeline Graph Data
      .addCase(fetchTimelineGraph.pending, (state) => {
        state.timelineGraph.loading = true;
      })
      .addCase(fetchTimelineGraph.fulfilled, (state, action) => {
        const { timelineData, timeEvents, timelineLogs, timestamps , events} = action.payload;
        
        state.timelineGraph.timestamps = timestamps;
        state.timelineGraph.timelineData = timelineData;
        state.timelineGraph.timelineEvents = timeEvents;
        state.timelineGraph.logs = timelineLogs;
        state.timelineGraph.loading = false;
        state.loading = false;

        state.timelineGraph.deviceEvents = formatDeviceEventData(events);
        state.status = "timeline_graph_loaded";
      })
      .addCase(fetchTimelineGraph.rejected, (state) => {
        state.status = "error_fetching_timeline_graph";
      })

      // Throughput Graph Data
      .addCase(fetchDeviceMetrics.fulfilled, (state, action) => {
        const { data, interval, resolution } = action.payload;

        data.forEach((obj) => {
          const metricData = obj.metricData.map((metric) => {
            if (!metric.dataPoints.length) return [];

            try {
              return fillTimeSeriesData({
                data: metric.dataPoints,
                startTime: interval.startTime,
                endTime: interval.endTime,
                interval: resolution,
                name: obj.metric,
                fillType: "null",
                modifier: ["device_download_throughput", "device_upload_throughput"].includes(obj.metric)
                  ? (v) => v * 8
                  : (v) => v,
              });
            } catch (e) {
              console.error('Error in fillTimeSeries', e, obj.metric, metric.dataPoints);
              return metric.dataPoints.length ? metric.dataPoints : [];
            }
          });
          state.metrics[obj.metric] = metricData[0];
        });

        state.loading = false;
        state.status = "throughput_graph_loaded";
      })
      .addCase(fetchDeviceMetrics.rejected, (state) => {
        // remove the metrics from the state
        networkMetricsList.forEach((metric) => {
          delete state.metrics[metric];
        });
        state.status = `error_fetching_throughput_graph`;
      })

      .addCase(fetchConnectionQualityMetrics.fulfilled, (state, action) => {
        const { data, interval, resolution } = action.payload;
        data.forEach((obj) => {
          const metricData = obj.metricData.map((metric) => {
            if (!metric.dataPoints.length) return [];
            try {
              return fillTimeSeriesData({
                data: metric.dataPoints,
                startTime: interval.startTime,
                endTime: interval.endTime,
                interval: resolution,
                name: obj.metric,
                fillType: "null",
              });
            }
            catch (e) {
              console.error('Error in fillTimeSeries', e, obj.metric, metric.dataPoints);
              return metric.dataPoints.length ? metric.dataPoints : [];
            }
          });
          state.metrics[obj.metric] = metricData[0];
        });

        state.loading = false; 
        state.status = "connection_quality_graph_loaded";
      })

      .addCase(fetchConnectionQualityMetrics.rejected, (state, action) => {
        // remove the metrics from the state
        deviceMetricsList.forEach((metric) => {
          delete state.metrics[metric];
        });
        state.status = `error_fetching_connection_quality_graph`;
      })

      // Radio Connection Quality
      .addCase(fetchRadioConnectionsQuality.fulfilled, (state, action) => {
        const prev = JSON.stringify(state.radioConnectionsQuality);
        const raw = JSON.stringify(action.payload);
        if (!(raw === prev)) {
          const data = action.payload;
          const payloads = data.map((obj) => {
            return {
              ...obj,
              metricData: obj.metricData.map((metric) => {
                return {
                  ...metric,
                  dataPoints: fillTimeSeriesData({
                    name: obj.metric,
                    data: metric.dataPoints,
                    startTime: action.meta.arg.interval.startTime,
                    endTime: action.meta.arg.interval.endTime,
                    interval: action.meta.arg.resolution,
                  }),
                };
              }),
            };
          });
          state.radioConnectionsQuality = payloads;
        }
        state.loading = false;
      })
      .addCase(fetchRadioConnectionsQuality.rejected, (state) => {
        state.radioConnectionsQuality = [];
        state.status = `error_fetching_radio_quality_graph`;
      })
      .addCase(fetchDevicesLatestMetrics.fulfilled, (state, action) => {
        const data = action.payload;
        state.currentMetrics = data;
        // update the devices with the latest metrics
        const devices = state.data;
        const _devices = SyncWithMetrics(devices, data);
        state.data = _devices;

        // sync the current subscriber with the new data
        if (state.current && !state.open) {
          const current = state.data.find((device) => device.id === state.current?.id);
          if (current) {
            state.current = current;
          }
        }

        state.loading = false;
      })
      .addCase(fetchPingmon.fulfilled, (state, action) => {
        const { data, raw } = action.payload;
        state.pingmon.pingmonMetrics = data;
        state.pingmon.raw = raw;
        state.loading = false;
      })
      .addCase(fetchPingmon.rejected, (state) => {
        state.status = `error_fetching_pingmon`;
      })
      .addCase(fetchSubscriberDeviceInfo.fulfilled, (state, action) => {
        state.dataDeviceInfo = action.payload;
      });
  },
});

export const subscriberActions = subscriberSlice.actions;
export default subscriberSlice.reducer;
export const subscriberOpen = (state: RootState) => state.subscriber.open;
export const subscriberHiddenColumns = (state: RootState) => state.subscriber.hiddenColumns;
export const subscriberPreset = (state: RootState) => state.subscriber.preset;

export const subscriberImportOpen = (state: RootState) => state.subscriber.importOpen;
export const subscriberDetailsOpen = (state: RootState) => state.subscriber.detailsOpen;

export const subscriberNetworksData = (state: RootState) => state.subscriber.networksData;
export const subscriberCurrentNetwork = (state: RootState) => state.subscriber.currentNetwork;
export const subscriberLoading = (state: RootState) => state.subscriber.loading;
export const subscriberNetworkOpen = (state: RootState) => state.subscriber.networkOpen;

export const subscriberData = (state: RootState) => state.subscriber.data;
export const subscriberSelected = (state: RootState) => state.subscriber.current;
export const subscriberTimelineGraphLoading = (state: RootState) => state.subscriber.timelineGraph.loading;
export const subscriberDeviceInfoData = (state: RootState) => state.subscriber.dataDeviceInfo;

export const subscriberTimelineData = (state: RootState) => state.subscriber.timelineGraph.timelineData;
export const subscriberTimelineEvents = (state: RootState) => state.subscriber.timelineGraph.timelineEvents;
export const subscriberTimelineLogs = (state: RootState) => state.subscriber.timelineGraph.logs;

export const radioConnectedToDevice =  (state: RootState) => {
  const device = state.subscriber.current;
  const radioId = device?.runtimeInfo?.radioId;
  return state.radio.data.find((radio) => radio.id === radioId) ?? null;
}

export const subscriberRadioConnectionQuality = (state: RootState) => state.subscriber.radioConnectionsQuality;
export const subscriberMetrics = (state: RootState) => state.subscriber.metrics;
export const subscriberMetric = (metric: string) => (state: RootState) => state.subscriber.metrics[metric];
export const pingmonMetrics = (state: RootState) => state.subscriber.pingmon.pingmonMetrics;
export const pingmonRawData = (state: RootState) => state.subscriber.pingmon.raw;

export const subscriberDeviceGroupsMapping = (state: RootState) => state.subscriber.deviceGroupMappingID;
export const subscriberConnectedDeviceGroups = (state: RootState) => state.subscriber.connectedDeviceGroups;

export const subscriberMetricsError = (state: RootState) => state.subscriber.status.startsWith("error");

function SyncDeviceWithRuntimeRadioAPs(data: Device[], radioAPs: Radio[]) {
  const devices = data.map((row, i) => {
    const _device = row;

    // find the radio ap name from the radio aps list
    let radioApName = radioAPs?.find((radioAp) => radioAp.id === _device?.runtimeInfo?.radioId)?.name;
    if (!radioApName && _device?.runtimeInfo?.radioApId) {
      // console.log("Finding radio from cell parameters");
      radioApName = radioAPs?.find((radioAp) =>
        radioAp?.cellParameters?.find((cell) => cell?.cellId === _device?.runtimeInfo?.radioApId)
      )?.name;
    }
    // if radio ap name is not found, set eci as the radio ap name
    if (!radioApName && _device?.runtimeInfo?.radioId) {
      radioApName = _device?.runtimeInfo?.eci?.toString();
    }
    if (_device.runtimeInfo && radioApName) {
      // console.log("Found radio from data : " + radioApName);
      _device.runtimeInfo.radioApName = radioApName;
      _device.runtimeInfo.radioID = _device?.runtimeInfo?.radioId || _device?.runtimeInfo?.radioApId;
    }
    return _device;
  });
  return devices;
}

function SyncWithMetrics(
  devices: Device[],
  metricsObject: {
    [key: string]: DeviceMetrics;
  }
) {
  return devices.map((device) => {
    const deviceMetrics = metricsObject[device.id!];
    if (deviceMetrics) {
      device.metrics = deviceMetrics;
    }
    return device;
  });
}
