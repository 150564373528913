import { jsx as e, jsxs as c } from "react/jsx-runtime";
import { useChatbotContext as p } from "./hooks/index.js";
import { c as f } from "../../createSvgIcon-78894459.js";
const u = f(/* @__PURE__ */ e("path", {
  d: "M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z"
}), "Mic"), C = ({
  placeholder: r = "Type your message here..."
}) => {
  const { prompt: s, setPrompt: o, send: d, isProcessing: t, speech: i, inputRef: l } = p(), a = (n) => {
    n.preventDefault();
    const h = s.trim();
    t || h && (d(s), o(""));
  };
  function m() {
    i.isListening ? i.stopListening() : i.startListening();
  }
  return /* @__PURE__ */ c("form", { className: "chatbot-footer", onSubmit: a, children: [
    /* @__PURE__ */ e("textarea", { disabled: t, className: "chatbot-input", rows: 3, value: s, placeholder: r, ref: l, onChange: (n) => {
      o(n.target.value);
    } }),
    /* @__PURE__ */ c("div", { className: "chatbot-actions", children: [
      /* @__PURE__ */ e("div", { id: "record", className: `action ${i.isListening ? "animate-recording" : ""} ${t ? "disabled" : ""}`, onClick: m, children: /* @__PURE__ */ e(u, {}) }),
      /* @__PURE__ */ e("div", { id: "send", className: `action ${t ? "disabled" : ""}`, onClick: a, children: "➤" })
    ] })
  ] });
};
export {
  C as default
};
